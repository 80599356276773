import React from "react";
import { Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

export const StyledHeader = styled(Typography)(({theme}) => ({
    color: "#91C788",
    fontWeight: 400,
    mb: .5

}));


